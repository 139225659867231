import { ModelName } from '#app/utils/modelNames.ts'
import { models } from '#app/utils/models.ts'
import { useProjectData } from '#app/utils/useProjectData.tsx'
import { useMatches } from '@remix-run/react'
import {
	Breadcrumb,
	BreadcrumbItem,
	BreadcrumbLink,
	BreadcrumbList,
	BreadcrumbSeparator,
} from './ui/breadcrumb'
import { Icon } from './ui/icon'

function BreadcrumbStandard({ href, label }: { href: string; label: string }) {
	return (
		<>
			<BreadcrumbSeparator />
			<BreadcrumbItem>
				<BreadcrumbLink href={href}>{label}</BreadcrumbLink>
			</BreadcrumbItem>
		</>
	)
}

function ListBreadcrumb({
	modelName,
	projectId,
}: {
	modelName: ModelName
	projectId: string
}) {
	return (
		<BreadcrumbStandard
			href={models[modelName].listUrl(projectId)}
			label={models[modelName].displayNames.plural}
		/>
	)
}

function DetailsBreadcrumb({
	id,
	label,
	modelName,
	projectId,
}: {
	id: string
	label: string
	modelName: ModelName
	projectId: string
}) {
	return (
		<BreadcrumbStandard
			href={models[modelName].detailsUrl(id, projectId)}
			label={label}
		/>
	)
}

function extractModelName(matchId: string) {
	const pattern = /routes\/_internal\+\/projects\+\/\$projectId\+\/(.*)\+\//
	const match = pattern.exec(matchId)?.[1]
	if (!match) {
		return null
	}

	const modelName = Object.values(models).find((model) => {
		return (
			match.toLowerCase() === model.displayNames.lowerPlural.replace(/\s+/g, '')
		)
	})?.name
	return modelName
}

function getBreadcrumbsFromMatches(): {
	breadcrumbs: React.ReactNode
	forceShowHome: boolean
} {
	let breadcrumbs: React.ReactNode = null
	const matches = useMatches()
	const relevantMatches = matches.slice(4)

	// get the obj name
	const data = relevantMatches[0]?.data
	const name = (data &&
		typeof data === 'object' &&
		'obj' in data &&
		(data as { obj: { name: string } }).obj.name) as string | undefined

	const modelName = extractModelName(relevantMatches[0]?.id)
	if (modelName == null) {
		return { breadcrumbs: null, forceShowHome: false }
	}
	let forceShowHome = false

	if (relevantMatches.some((match) => match.id.endsWith('$id'))) {
		const { projectId } = relevantMatches[0].params

		breadcrumbs = (
			<>
				<ListBreadcrumb
					key="list"
					modelName={modelName}
					projectId={projectId ?? ''}
				/>
			</>
		)
	} else if (relevantMatches.some((match) => match.id.endsWith('list.index'))) {
		forceShowHome = true
	} else if (relevantMatches.some((match) => match.id.endsWith('new'))) {
		const { projectId } = relevantMatches[0].params

		breadcrumbs = (
			<>
				<ListBreadcrumb
					key="list"
					modelName={modelName}
					projectId={projectId ?? ''}
				/>
			</>
		)
	} else if (relevantMatches.some((match) => match.id.endsWith('edit'))) {
		const { id: objId, projectId } = relevantMatches[0].params

		breadcrumbs = (
			<>
				<ListBreadcrumb
					key="list"
					modelName={modelName}
					projectId={projectId ?? ''}
				/>
				<DetailsBreadcrumb
					id={objId ?? ''}
					key="details"
					label={name ?? ''}
					modelName={modelName}
					projectId={projectId ?? ''}
				/>
			</>
		)
	}
	return { breadcrumbs, forceShowHome }
}

export default function BreadcrumbsImpl() {
	const { project } = useProjectData() ?? {}
	const { breadcrumbs, forceShowHome } = getBreadcrumbsFromMatches()
	return (
		<Breadcrumb>
			<BreadcrumbList>
				<BreadcrumbItem>
					{breadcrumbs !== null || forceShowHome ? (
						<BreadcrumbLink href={`/projects/${project?.id}`}>
							<Icon className="mb-0.5" name="home" />
						</BreadcrumbLink>
					) : null}
				</BreadcrumbItem>
				{breadcrumbs}
			</BreadcrumbList>
		</Breadcrumb>
	)
}
