import { cn } from '#app/utils/misc.ts'
import { models } from '#app/utils/models.ts'
import { Link, useMatches, useNavigate } from '@remix-run/react'
import { Button } from './ui/button'
import { Separator } from './ui/separator'
import { SidebarTrigger } from './ui/sidebar'

export interface Step {
	model: (typeof models)[keyof typeof models]
}

interface OnboardingProgressProps {
	canProceed?: (currentStep: number) => boolean
	projectId: string
	steps: Step[]
}

export default function OnboardingProgress({
	canProceed = () => true,
	projectId,
	steps,
}: OnboardingProgressProps) {
	const matches = useMatches()
	const navigate = useNavigate()

	const aStep = steps.findIndex((step) =>
		matches.some((match) =>
			match.pathname.includes(step.model.listUrl(projectId)),
		),
	)
	const currentStep = aStep === -1 ? 0 : aStep

	const handleBack = () => {
		const index = Math.max(0, currentStep - 1)
		navigate(steps[index].model.listUrl(projectId))
	}
	const handleNext = () => {
		const index = Math.min(steps.length - 1, currentStep + 1)
		navigate(steps[index].model.listUrl(projectId))
	}

	const currentStepModel = steps[currentStep].model
	if (aStep === -1) {
		return <SidebarTrigger className="mx-3 mt-[22px]" />
	}

	return (
		<>
			<div className="flex justify-center">
				<div className="w-full mx-4">
					<div className="flex justify-between items-center pt-4 pb-3">
						<SidebarTrigger className="-ml-1" />
						<Link className="link" to={currentStepModel.listUrl(projectId)}>
							Create {currentStepModel.displayNames.plural}
						</Link>
						<div className="flex space-x-2">
							<Button className="" onClick={handleBack} variant="link">
								Back
							</Button>
							<Button
								className=""
								disabled={
									currentStep === steps.length - 1 || !canProceed(currentStep)
								}
								onClick={handleNext}
							>
								Next
							</Button>
						</div>
					</div>
					<div className="relative">
						<div className="flex justify-center space-x-2 mx-0.5">
							{[...Array(steps.length)].map((_, index) => (
								<div
									className={cn(
										`h-1 mb-1 w-full transition-colors duration-300 rounded-full`,
										index <= currentStep ? 'bg-primary/70' : 'bg-gray-300',
									)}
									key={index}
								/>
							))}
						</div>
					</div>
				</div>
			</div>
			<Separator className="mt-2" />
		</>
	)
}
