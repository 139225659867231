import * as React from 'react'

import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuLabel,
	DropdownMenuSeparator,
	DropdownMenuTrigger,
} from '#app/components/ui/dropdown-menu'
import {
	SidebarMenu,
	SidebarMenuButton,
	SidebarMenuItem,
	useSidebar,
} from '#app/components/ui/sidebar'
import { useProjectData } from '#app/utils/useProjectData.tsx'
import { Link } from '@remix-run/react'
import { Icon } from './ui/icon'

export function ProjectSwitcher({
	projects,
}: {
	projects: {
		id: string
		name: string
	}[]
}) {
	const { isMobile } = useSidebar()
	const { canNewProject, project } = useProjectData() ?? {}
	const [activeProject, setActiveProject] = React.useState(
		(project ?? projects.length > 0) ? project : null,
	)

	const addNewProjectButton = (
		<>
			<div className="flex size-6 items-center justify-center rounded-md border bg-background">
				<Icon className="size-4" name="plus" />
			</div>
			<div className="font-medium text-muted-foreground">Add Project</div>
		</>
	)

	return (
		<SidebarMenu>
			<SidebarMenuItem>
				<DropdownMenu>
					<DropdownMenuTrigger asChild>
						<SidebarMenuButton
							className="data-[state=open]:bg-sidebar-accent data-[state=open]:text-sidebar-accent-foreground"
							size="lg"
						>
							<div className="flex aspect-square size-8 items-center justify-center rounded-lg border text-sidebar-primary-foreground">
								<img alt="icon" className="size-5 block" src="/favicon.svg" />
							</div>
							<div className="grid flex-1 text-left text-sm leading-tight">
								<span className="truncate font-semibold">
									{activeProject?.name}
								</span>
								{/* <span className="truncate text-xs">{activeTeam.plan}</span> */}
							</div>
							<Icon className="ml-auto" name="chevrons-up-down" />
						</SidebarMenuButton>
					</DropdownMenuTrigger>
					<DropdownMenuContent
						align="start"
						className="w-[--radix-dropdown-menu-trigger-width] min-w-56 rounded-lg"
						side={isMobile ? 'bottom' : 'right'}
						sideOffset={4}
					>
						<Link to={canNewProject ? '/projects/new' : '/pricing'}>
							<DropdownMenuItem className="gap-2 p-2">
								{addNewProjectButton}
							</DropdownMenuItem>
						</Link>
						<DropdownMenuSeparator />
						<DropdownMenuLabel className="text-xs text-muted-foreground">
							Projects
						</DropdownMenuLabel>
						{projects.map((project) => (
							<Link key={project.id} to={`/projects/${project.id}`}>
								<DropdownMenuItem
									className="gap-2 p-2 overflow-ellipsis"
									key={project.name}
									onClick={() => setActiveProject(project)}
								>
									{project.name.length > 20
										? `${project.name.slice(0, 30)}...`
										: project.name}
								</DropdownMenuItem>
							</Link>
						))}
					</DropdownMenuContent>
				</DropdownMenu>
			</SidebarMenuItem>
		</SidebarMenu>
	)
}
